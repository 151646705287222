import React, { useEffect, useState } from "react";

import Api from "./../../data/api";
import "./PasswordRecovery.css";

import logoHost from "./../../media/logo_host.png";
import { IsNullOrUndefined } from "../../helpers/HelperFunctions";
import { UserOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { HomeOutlined } from "@ant-design/icons/lib/icons";

export default function PasswordRecovery({ isPT }) {
  const connection = Api.getQueryVariable("connectionName");
  const user = Api.getQueryVariable("user");
  const token = Api.getQueryVariable("token");

  const [loading, setLoading] = useState(false);
  const [testConnectionResult, setTestConnectionResult] = useState(null);
  const [enDate, setEnDate] = useState(null);
  const [ptDate, setPtDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [loadingTest, setLoadingTest] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [newPassConfirm, setNewPassConfirm] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMessage2, setErrorMessage2] = useState(null);
  const [changePasswordMsg, setChangePasswordMsg] = useState(null);

  useEffect(() => {
    testConnection();

    var today = new Date();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    var date =
      today.getFullYear() +
      "-" +
      (month < 10 ? `0${month}` : month) +
      "-" +
      (day < 10 ? `0${day}` : day);
    setCurrentDate(date);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function testConnection() {
    setLoadingTest(true);
    setLoading(false)
    Api.testConnection(`System/TestConnection`, connection)
      .then((result) => {
        setTestConnectionResult(result);
        var options = {
          weekday: "short",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        var today = new Date(result.HotelDateFixedFormat);

        setEnDate(
          today.toLocaleDateString("en-US", options).charAt(0).toUpperCase() +
            today.toLocaleDateString("en-US", options).replace(".", "").slice(1)
        );
        setPtDate(
          today
            .toLocaleDateString("pt-BR", options)
            .replace(".", "")
            .charAt(0)
            .toUpperCase() +
            today.toLocaleDateString("pt-BR", options).replace(".", "").slice(1)
        );
        setLoadingTest(false);
      })
      .catch((error) => {
        setLoadingTest(false);
        setTestConnectionResult(null);
      });
  }

  function onFinish(event) {
    if (newPass.trim().length === 0) {
      setErrorMessage("Por favor introduza a palavra-passe!");
    } else {
      if (newPassConfirm.trim().length === 0) {
        setErrorMessage(null);
        setErrorMessage2("A confirmação da palavra-passe não coincide!");
      } else {
        if (newPass === newPassConfirm) {
          setErrorMessage(null);
          setErrorMessage2(null);
          changePassword();
        } else {
          setErrorMessage(null);
          setErrorMessage2("A confirmação da palavra-passe não coincide!");
        }
      }
    }
  }

  function changePassword() {
    Api.postData(`/account/PasswordRecoveryUpdatePassword`, {
      ConnectionName: connection,
      token: token,
      newPassword: newPass,
    })
      .then((result) => {
        setChangePasswordMsg(result);
      })
      .catch((error) => {});
  }

  return (
    <>
      {testConnectionResult !== null && (
        <div
          className="login-wrapper"
          style={{
            backgroundImage: IsNullOrUndefined(
              testConnectionResult.WallpaperImage
            )
              ? `url("images/login_background.jpg")`
              : `url(${window.location.origin}${testConnectionResult.WallpaperImage}?conn=${localStorage.lastConnectionV10Dashboard})`,
          }}
        >
          <div className="login-layout">
            {testConnectionResult !== null ? (
              <img
                className="img-hotel-logo"
                alt="img-hotel-logo"
                src={
                  testConnectionResult.HotelImage !== undefined
                    ? `${window.location.origin}${testConnectionResult.HotelImage}?w=200&conn=${localStorage.lastConnectionV10Dashboard}`
                    : "images/logo_hotel.png"
                }
              ></img>
            ) : (
              <img
                className="loading-gif"
                alt="loading-gif"
                src="images/spinning_loading.gif"
              ></img>
            )}
            {loading !== true ? (
              <>
                {/*  {!loadingOnly ? ( */}
                <form className="recovery-password-form" onSubmit={onFinish}>
                  <p className="form-title">
                    {isPT
                      ? "É necessário alterar a sua password!"
                      : "You must change your password!"}
                  </p>

                  <div className="form-user-info-wrapper">
                    <HomeOutlined className="form-user-info-icon" />{" "}
                    <p className="form-user-info">{connection}</p>
                  </div>
                  <div className="form-user-info-wrapper">
                    <UserOutlined className="form-user-info-icon" />{" "}
                    <p className="form-user-info">{user}</p>
                  </div>

                  <div className="divider"></div>

                  <p className="form-title">
                    {isPT
                      ? "Por favor introduza a sua nova password e a respectiva confirmação"
                      : "Please enter your new password and the confirmation"}
                  </p>

                  <label>
                    <input
                      disabled={changePasswordMsg !== null}
                      autoFocus
                      placeholder={isPT ? "Nova palavra-passe" : "New password"}
                      type="password"
                      value={newPass}
                      onChange={(e) => setNewPass(e.target.value)}
                    />
                  </label>
                  {errorMessage !== null && (
                    <span className="login-form-fields-error">
                      {errorMessage}
                    </span>
                  )}

                  <label>
                    <input
                      disabled={changePasswordMsg !== null}
                      placeholder={
                        isPT
                          ? "Nova palavra-passe (confirmação)"
                          : "New password (confirm)"
                      }
                      type="password"
                      value={newPassConfirm}
                      onChange={(e) => setNewPassConfirm(e.target.value)}
                    />
                  </label>
                  {errorMessage2 !== null && (
                    <span className="login-form-fields-error">
                      {errorMessage2}
                    </span>
                  )}
                  <button
                    type="button"
                    onClick={() => onFinish()}
                    className={
                      changePasswordMsg === null
                        ? "change-password-button"
                        : "change-password-button-disabled"
                    }
                  >
                    {isPT ? "Alterar palavra-passe" : "Change Password"}
                  </button>

                  {changePasswordMsg !== null && (
                    <div>
                      {changePasswordMsg.success ? (
                        <div>
                          <div className="error-message-success">
                            {isPT
                              ? "A palavra-passe for alterada!"
                              : "Password has been changed!"}
                          </div>
                          <button
                            type="button"
                            onClick={() =>
                              (window.location.href = `${window.location.origin}/Login`)
                            }
                            className="go-to-login-button"
                          >
                            <ArrowLeftOutlined />{" "}
                            {isPT
                              ? "Prosseguir para o início de sessão"
                              : "Proceed to login"}
                          </button>
                        </div>
                      ) : (
                        <div className="error-message-info">
                          {changePasswordMsg.changePwdMsg}
                        </div>
                      )}
                    </div>
                  )}
                </form>
                {/*  ) : (
              <img
                className="loading-gif"
                alt="loading-gif"
                src="images/spinning_loading.gif"
              ></img>
            )} */}
              </>
            ) : (
              <img
                className="loading-gif"
                alt="loading-gif"
                src="images/spinning_loading.gif"
              ></img>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minHeight: 110,
              }}
            >
              {loadingTest ? (
                <img
                  className="loading-gif"
                  alt="loading"
                  src="images/spinning_loading.gif"
                ></img>
              ) : (
                <>
                  <img
                    alt="host-logo"
                    className="img-host-logo"
                    src={logoHost}
                  ></img>

                  {testConnectionResult !== null ? (
                    <>
                      <span
                        style={{
                          marginTop: 10,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: 500,
                          fontSize: 14,
                          flexDirection: "column",
                        }}
                      >
                        <div className="tooltip">
                          {" "}
                          {
                            testConnectionResult.PmsVersion.Version.split(
                              "."
                            )[3]
                          }
                          <span className="tooltiptext">
                            {" "}
                            {testConnectionResult.PmsVersion.ServicePackInfo}
                          </span>
                        </div>

                        <span
                          style={{
                            margin: "10px 0 5px 0",
                            fontWeight: 300,
                            padding: "5px 10px",
                            borderRadius: "5px",
                            background:
                              currentDate !==
                                testConnectionResult.HotelDateFixedFormat &&
                              "#cdd4db",
                            color:
                              currentDate !==
                                testConnectionResult.HotelDateFixedFormat &&
                              "white",
                          }}
                        >
                          {isPT ? ptDate : enDate}
                        </span>
                        {testConnectionResult.IsTrain && (
                          <span
                            style={{
                              fontWeight: 300,
                              color: "#5c89ff",
                            }}
                          >
                            {isPT
                              ? "Modo treino ativo"
                              : "Active training mode"}
                          </span>
                        )}
                      </span>

                      <div
                        style={{
                          fontSize: 9,
                          color: "#8894a1",
                          position: "absolute",
                          bottom: 10,
                          right: 10,
                        }}
                      >
                        {testConnectionResult.showDbInfo ? (
                          <span>{`${testConnectionResult.ServerAlias} ; ${testConnectionResult.DatabaseName} ${testConnectionResult.PlatformUid}`}</span>
                        ) : (
                          <span>{testConnectionResult.PlatformUid}</span>
                        )}
                      </div>
                    </>
                  ) : (
                    <span
                      className="login-form-fields-error"
                      style={{
                        marginTop: 20,
                        fontSize: 12,
                        padding: "5px 10px",
                      }}
                    >
                      {isPT
                        ? "Ocorreu um erro, ligação inválida"
                        : "An error occured, invalid connection"}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
